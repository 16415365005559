import Link from 'next/link';

import { Row, Col, Typography } from 'antd';

import Logo from './Logo';

const { Title, Paragraph } = Typography;

const PageNotFound = () => {
  return (
    <Row
      justify="center"
      align="middle"
      style={{ minHeight: '100vh', textAlign: 'center' }}
      className="shophil-bg">
      <Col style={{ maxWidth: 450, width: '100%' }}>
        <Logo type="big" />
        <Paragraph>
          <Title level={2} style={{ color: 'white' }}>
            Page not found
          </Title>
        </Paragraph>
        <Paragraph style={{ color: 'white' }}>
          Oops! We can&apos;t find the page you&apos;re looking for.
        </Paragraph>
        <br />
        <Paragraph>
          <Link href="/">
            <a style={{ color: 'white' }}>Back to home</a>
          </Link>
        </Paragraph>
      </Col>
    </Row>
  );
};

export default PageNotFound;
